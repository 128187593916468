<template>
    <div class="dropdown-center" v-if="dataObject.allowDelete || dataObject.allowUpdate || customButtons">
        <button class="btn btn-outline-secondary border-0 py-0 px-1" type="button" data-bs-toggle="dropdown" aria-expanded="false" :disabled="disableDropdown">
            <i class="bi bi-three-dots-vertical"></i>
        </button>
        <ul class="dropdown-menu">
            <template v-if="row.Url">
                <li v-if="dataObject.allowUpdate || hasFileName">
                    <button class="dropdown-item" @click="showUrlModal = true">
                        <i class="bi bi-pencil-fill me-2"></i>
                        {{ $t("Edit URL") }}
                    </button>
                </li>
            </template>
            <template v-else>
                <li v-if="dataObject.allowUpdate || hasFileName">
                    <button class="dropdown-item" @click="renameModal.show">
                        <i class="bi bi-pencil-fill me-2"></i>
                        {{ $t("Rename") }}
                    </button>
                </li>
            </template>
            <li v-if="dataObject.allowDelete">
                <ODelete :row="row" class="dropdown-item" confirm>
                    <i class="bi bi-x-circle me-2"></i>
                    {{ $t("Delete") }}
                </ODelete>
            </li>
            <li v-if="geoLocationBtn">
                <LeafLetBtn  :row="row" :geoLocationConfig="geoLocationConfig"/>
            </li>
            <template v-for="button in customButtonComputed">
                <li>
                    <component class="dropdown-item" :id="button.id" :is="button.component" v-bind="button.props"></component>
                </li>
            </template>
        </ul>
    </div>

    <OModal ref="renameModal">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Rename file</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="input-group">
                        <input class="form-control" v-model="fileName"/>
                        <span class="input-group-text" id="basic-addon2">.{{ initialExtension }}</span>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-secondary btn-sm" @click="handleClose">Close</button>
                    <button class="btn btn-primary btn-sm" :disabled="setDisabled" @click="handleSave">Save</button>
                </div>
            </div>
        </div>
    </OModal>

    <UrlModal v-model="showUrlModal" :dataObject="row.$.dataObject" :row="row" />
</template>

<script setup lang="ts">
import { getDataObjectById } from 'o365-dataobject';
import { ref, computed } from 'vue';
import { OActionDelete as ODelete } from 'o365-data-components';
import { OModal } from 'o365-ui-components';
import useAsyncComponent from 'o365.vue.composables.AsyncComponent.ts';
import UrlModal from "./components.Attachments.UrlModal.vue";
import { $t } from 'o365-utils';

const LeafLetBtn = useAsyncComponent("o365.vue.components.Attachments.LeafletBtn.vue");


const props = defineProps({
    row: Object,
    customButtons: Array,
    geoLocationBtn:Boolean,
    geoLocationConfig:Object,
    disableDropdown: Boolean
})

const customButtonComputed = computed(() => {
    var res = []
    if(!props.customButtons){
        return res;
    }
    props.customButtons.forEach(x => {
        if(x.props){
            x.props.row = props.row
        }
        res.push(x);
    })

    return res;
});

const renameModal = ref(null);
const showUrlModal = ref(false);

const dataObject = computed(() => {
    const dataObjectId = props.row.dataObjectId;
    const appId = props.row.appId;
    if (dataObjectId) {
        return getDataObjectById(dataObjectId, appId);
    } else {
        return undefined;
    }
});

const hasFileName = computed(() => {
    if (props.row.FileName) return true;
    return false;
})

const hasExtension = computed(() => {
    if (hasFileName.value) {
        if (props.row.FileName.split('.').length > 1) return true;
        return false;
    }
    return false;
})

const initialExtension = hasFileName.value 
    ? hasExtension.value
        ? props.row.FileName.split('.').pop()
        : '' 
    : null;
let initialFileName = hasFileName.value 
    ? hasExtension.value 
        ? props.row.FileName.split('.').slice(0, -1).join('.')
        : props.row.FileName 
    : null;

const fileName = ref(hasFileName.value 
    ? hasExtension.value 
        ? props.row.FileName.split('.').slice(0, -1).join('.')
        : props.row.FileName 
    : null);

function handleClose() {
    renameModal.value.hide();
    fileName.value = initialFileName;
}

async function handleSave() {
    renameModal.value.hide();
    initialFileName = fileName.value
    const newFileName = fileName.value + '.' + initialExtension;
    props.row.FileName = newFileName;
    props.row.save(); 
}

const setDisabled = computed(() => {
    return fileName.value === initialFileName
})

function handleHidden(e) {
    fileName.value = initialFileName;
}
</script>